<template>
    <div class="import-dialog">
        <el-dialog
            :visible.sync="show"
            :close-on-click-modal="false"
            :title="type * 1 === 1 ? '导入' : '导出'"
            :before-close="importSubmit"
            :width="type * 1 !== 2 ? '85%' : '500px'"
            append-to-body
            v-loading="btnLoading"
        >
            <div
                v-if="type * 1 !== 2"
                v-loading="divLoading"
                :element-loading-text="loading_text"
            >
                <div>
                    <div class="mb-20">
                        <el-steps :active="stepsActive" align-center>
                            <el-step
                                v-for="(item, index) in stepList"
                                :key="index"
                            >
                                <i class="iconfont icon--jinbi" slot="icon"></i>
                                <div
                                    slot="title"
                                    class="font-size-14 font-weight-400"
                                    v-text="item.title"
                                ></div>
                            </el-step>
                            <!-- <el-step>
                                <i class="iconfont icon--jinbi" slot="icon"></i>
                                <div
                                    slot="title"
                                    class="font-size-14 font-weight-400"
                                >
                                    数据预览
                                </div>
                            </el-step>
                            <el-step>
                                <i class="iconfont icon--jinbi" slot="icon"></i>
                                <div
                                    slot="title"
                                    class="font-size-14 font-weight-400"
                                >
                                    导入设置
                                </div>
                            </el-step>
                            <el-step>
                                <i class="iconfont icon--jinbi" slot="icon"></i>
                                <div
                                    slot="title"
                                    class="font-size-14 font-weight-400"
                                >
                                    提交人设置
                                </div>
                            </el-step>
                            <el-step>
                                <i class="iconfont icon--jinbi" slot="icon"></i>
                                <div
                                    slot="title"
                                    class="font-size-14 font-weight-400"
                                >
                                    导入数据
                                </div>
                            </el-step> -->
                        </el-steps>
                    </div>
                    <div style="margin: 0 auto; width: 100%">
                        <div class="" v-if="steps_type === 'select'">
                            <el-upload
                                class="import-upload"
                                :auto-upload="true"
                                :multiple="false"
                                drag
                                :before-upload="upload"
                                action="#"
                                :accept="'.xls , .xlsx'"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    将文件拖到此处，或<em>点击上传</em>
                                </div>
                            </el-upload>

                            <div class="import-upload-tips1" v-if="isTemplate">
                                <i
                                    class="el-icon-download"
                                    style="font-size: 25px"
                                ></i>
                                请先下载
                                <span
                                    style="color: #1890ff; cursor: pointer"
                                    @click="download"
                                    target="downloadFile"
                                >
                                    导入模板</span
                                >
                                ，并按照规范示例录入数据后上传
                            </div>
                            <div class="import-upload-tips">
                                上传的Excel符合以下规范:<br />
                                .文件大小不超过10M，目单个sheet页数据量不超过5000行。当触发校验规则，单个sheet
                                页数据量不超过5000行<br />
                                .仅支持(*.xls和*.xsx)文件<br />
                                ·请确保您需要导入的sheet头中不包含空的单元格，否则该sheet数据系统将不做导入
                            </div>
                        </div>
                        <div
                            v-if="steps_type === 'preview'"
                            class="import-upload-list"
                        >
                            <!-- <el-form :inline="true">
												<el-form-item label="工作表">
												
												</el-form-item>
												<el-form-item label="导入说明" style="width: 100%;">
													<el-input placeholder="请输入导入说明" v-model="form.sm"></el-input>
												</el-form-item>
											</el-form> -->
                            <div class="import-upload-form">
                                <div class="import-upload-form-item">
                                    <div class="import-upload-form-label">
                                        工作表
                                        <span class="import-required">*</span>
                                    </div>
                                    <el-select
                                        v-model="form.region"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        @change="worksheetChange"
                                    >
                                        <el-option
                                            :label="item.wsname"
                                            :value="index"
                                            v-for="(item, index) in worksheet"
                                            :key="index"
                                        ></el-option>
                                    </el-select>
                                    <div
                                        class="required-tips"
                                        v-text="'请选择工作表'"
                                    ></div>
                                </div>
                                <!-- <div class="import-upload-form-item ml-20">
                                    <div class="import-upload-form-label">
                                        导入说明
                                          <span class="import-required">*</span>  
                                    </div>
                                    <el-input
                                        placeholder="请输入导入说明"
                                        v-model="form.sm"
                                    ></el-input>
                                    <div
                                        class="required-tips"
                                        v-text="'请选择导入说明'"
                                    ></div>
                                </div> -->
                            </div>
                            <div class="import-upload-tips">
                                数据最多展示一百条数据
                            </div>

                            <!-- <vxe-grid resizable ref="xGrid" row-id="id" stripe border show-overflow highlight-hover-row
								:loading="loading" :columns="titleColumn" :data="dataList">
							</vxe-grid> -->
                            <jf-table
                                :columns="importColumns"
                                :checkbox="false"
                                :operate="false"
                                :filter-btn="false"
                                :columns-btn="false"
                                :sort-btn="false"
                                :data="importList"
                                :max-height="400"
                                :pager="false"
                            ></jf-table>
                        </div>
                        <div
                            class="import-set-up my-50 import-el-table"
                            v-if="steps_type === 'import_setting'"
                        >
                            <!-- <jf-table
                                :columns="importColumns"
                                :checkbox="false"
                                :operate="false"
                                :filter-btn="false"
                                :columns-btn="false"
                                :sort-btn="false"
                                :data="importList"
                                :max-height="400"
                                :pager="false"
                                v-if="!btnLoading"
                            >
                                <template
                                    slot="defaultcategory"
                                    slot-scope="{ row }"
                                >
                                </template>
                            </jf-table> -->
                            <el-table
                                :data="[{}]"
                                border
                                size="mini"
                                style="width: 100%; margin-bottom: 20px"
                                :header-row-style="{
                                    background: '#f8f8f9 !important',
                                    height: '44px',
                                }"
                                max-height="350px"
                                v-if="!btnLoading"
                            >
                                <el-table-column
                                    prop="no"
                                    :label="item.title"
                                    align="center"
                                    v-for="(item, index) in importColumns"
                                    :key="index"
                                >
                                    <template slot-scope="{ row }">
                                        <el-select
                                            v-model="item.value"
                                            placeholder="请选择"
                                            style="width: 100%"
                                            clearable
                                            v-if="type * 1 === 1"
                                            @change="
                                                (e) =>
                                                    columnsValueChange(
                                                        e,
                                                        item.title
                                                    )
                                            "
                                        >
                                            <el-option
                                                :label="value.title"
                                                :value="value.field"
                                                v-for="(
                                                    value, value_i
                                                ) in columns"
                                                :key="value_i"
                                                :disabled="
                                                    importColumnsDisabled(
                                                        item,
                                                        value.field,
                                                        row
                                                    )
                                                "
                                            ></el-option>
                                        </el-select>
                                        <el-input
                                            v-model="item.value"
                                            v-if="type * 1 === 3"
                                            placeholder="请输入"
                                        ></el-input>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div
                                class="d-flex align-items-center justify-content-center"
                                v-else
                            >
                                加载数据中...
                            </div>
                            <div class="">
                                <el-checkbox v-model="importoption.isvalidate"
                                    >同时触发校验规则</el-checkbox
                                >
                            </div>
                        </div>
                        <div
                            v-if="steps_type === 'user_setting'"
                            class="presenter-set-up my-50"
                        >
                            <div class="import-upload-form">
                                <div class="import-upload-form-item">
                                    <div class="import-upload-form-label">
                                        提交人
                                        <span class="import-required">*</span>
                                    </div>
                                    <el-radio-group
                                        v-model="importoption.oprusertype"
                                    >
                                        <el-radio label="1"
                                            >当前导入人</el-radio
                                        >
                                        <!-- <el-radio label="宜搭平台">宜搭平台</el-radio> -->
                                        <!-- <el-radio label="表单字段" disabled
                                            >表单字段(对应为表单内的选人组件)</el-radio
                                        > -->
                                    </el-radio-group>
                                    <!-- 	<div class="required-tips" v-text="'请选择工作表'"></div> -->
                                </div>
                            </div>
                            <!-- <div class="import-upload-form">
                                <div class="import-upload-form-item">
                                    <div class="import-upload-form-label">
                                        {{ form.radio || "当前导入人" }}
                                        <span class="import-required">*</span>
                                    </div>
                                    <el-select
                                        v-model="form.region"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        disabled
                                    >
                                        <el-option
                                            label="成员"
                                            value="shanghai"
                                        ></el-option>
                                    </el-select>
                                    <div
                                        class="required-tips"
                                        v-text="'请选择导入说明'"
                                    ></div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div
                    v-if="stepsActive === stepList.length"
                    class="import-success"
                >
                    <i class="el-icon-circle-check import-success-icon"></i>
                    <div>导入完成</div>
                    <div class="import-success-tips">
                        关闭弹窗后可在<span class="text-primary"
                            >数据管理-导入记录</span
                        >查看
                    </div>
                </div>
                <div
                    id=""
                    class="justify-content-end d-flex"
                    style="margin: 20px"
                >
                    <el-button
                        v-if="
                            stepsActive !== 1 && stepsActive !== stepList.length
                        "
                        @click="stepsActiveChange(1)"
                        >上一步</el-button
                    >
                    <el-button
                        type="primary"
                        @click="stepsActiveChange(2)"
                        v-if="stepsActive !== stepList.length"
                        :disabled="submitDisabled"
                        :loading="btnLoading"
                        >{{
                            stepsActive === stepList.length - 1
                                ? "确认导入"
                                : btnLoading
                                ? "加载中"
                                : "下一步"
                        }}</el-button
                    >

                    <!-- <el-button
                        type="primary"
                        v-if="stepsActive === 5"
                        @click="importSubmit(1)"
                        >保存模板</el-button
                    > -->
                    <el-button
                        v-if="stepsActive === stepList.length"
                        @click="importSubmit"
                        >关闭</el-button
                    >
                </div>
            </div>
            <div v-else>
                <div class="import-upload-form">
                    <div class="import-upload-form-item">
                        <div class="import-upload-form-label">导出模板</div>
                        <el-select
                            v-model="template_id"
                            placeholder="请选择"
                            style="width: 100%"
                            @change="templateChange"
                            clearable
                        >
                            <el-option
                                v-for="(item, index) in exportTemplateList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="">
                    请选择导出字段(将导出{{ exportTotal }}条数据)
                </div>
                <div class="column">
                    <el-input
                        placeholder="输入筛选条件"
                        v-model="filterKeywords"
                        suffix-icon="el-icon-search"
                    ></el-input>
                    <div style="margin-top: 10px">
                        <el-checkbox
                            :indeterminate="
                                checkboxLength !== 0 &&
                                checkboxLength !== filterData.length
                            "
                            :value="checkboxLength === filterData.length"
                            @change="allCheckboxChange"
                            >全选 {{ checkboxLength }}/{{
                                filterData.length
                            }}</el-checkbox
                        >
                    </div>
                    <div class="column-list">
                        <draggable v-model="filterList">
                            <div
                                class="column-list-item"
                                v-for="(item, index) in filterData"
                                :key="index"
                            >
                                <!-- <i class="iconfont icon"
										:class="item.isCheck?'text-primary icon-checkbox-checked-fill':'icon-square'"
										@click="checkboxChange(item)"></i> -->
                                <el-checkbox
                                    :value="item.isCheck"
                                    @change="checkboxChange(item)"
                                ></el-checkbox>
                                <div
                                    v-text="item.title"
                                    class="ml-10 flex-1"
                                ></div>

                                <el-input
                                    placeholder="请输入"
                                    v-model="item.byname"
                                    size="mini"
                                    style="width: 105px"
                                >
                                    <template slot="prepend"
                                        >别名</template
                                    ></el-input
                                >
                                <!-- <el-checkbox 
										:value="item.fixed==='left'" @change="fixedChange(item)">全选
										{{checkboxLength}}/{{list.length}}</el-checkbox> -->
                                <i
                                    class="iconfont ml-10 icon-apps icon-caiddang"
                                ></i>
                            </div>
                        </draggable>
                    </div>
                </div>
                <div
                    id=""
                    class="justify-content-end d-flex"
                    style="margin: 20px"
                >
                    <el-button type="success" @click="importSubmit(5)"
                        >存为模板</el-button
                    >
                    <el-button @click="importSubmit">取消</el-button>

                    <el-button type="primary" @click="exportSubmit"
                        >确定</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { read, utils } from "xlsx";
import draggable from "vuedraggable";

export default {
    components: {
        draggable,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        type: {
            type: [String, Number],
            default: "1",
        },

        columns: {
            type: Array,
            default: () => [],
        },
        isfinish: {
            type: Boolean,
            default: false,
        },
        mid: {
            type: String,
            default: "",
        },
        downloadtplurl: {
            type: String,
            default: "",
        },
        downloadtplfile: {
            type: String,
            default: "",
        },
        //是否导入失败
        importError: {
            type: Boolean,
            default: false,
        },
        //导出总数量
        exportTotal: {
            type: [String, Number],
            default: 0,
        },
        //步骤条
        stepList: {
            type: Array,
            default: () => [
                { id: "1", title: "选择EXCEL表", type: "select" },
                { id: "2", title: "数据预览", type: "preview" },
                { id: "3", title: "导入设置", type: "import_setting" },
                { id: "4", title: "提交人设置", type: "user_setting" },
                { id: "5", title: "导入数据", type: "import_data" },
            ],
        },
        isTemplate: {
            type: Boolean,
            default: true,
        },
        exportTemplateList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            data: [],
            importTitle: "导入",
            filterKeywords: "",
            filterList: [],
            stepsActive: 1,
            steps_type: "",
            form: {},
            setUpData: [{}],
            checked: false,
            importColumns: [],
            importList: [],
            importoption: {
                isvalidate: true, //是否开启验证数据
                oprusertype: "1", //1当前导入人
            },
            worksheet: [],
            importFile: "",
            divLoading: false,
            loading_text: "",
            template_id: "",
            checkedColumnsValue: [],
            btnLoading: false,
            filename: "",
        };
    },
    computed: {
        filterData: function () {
            return this.filterList.filter(
                (o) =>
                    o.title &&
                    o.title !== "操作" &&
                    o.title.indexOf(this.filterKeywords) !== -1
            );
        },
        checkboxLength: function () {
            let index = 0;
            this.filterList.forEach((item) => {
                if (item.isCheck) {
                    index += 1;
                }
            });
            return index;
        },
        submitDisabled: function () {
            switch (this.steps_type) {
                case "select":
                    return this.worksheet.length > 0 ? false : true;
                case "preview":
                    return false;

                case "import_setting":
                    this.$nextTick(() => {
                        return this.importColumns.findIndex(
                            (o) => o.value && o.value.length
                        ) > -1
                            ? false
                            : true;
                    });
            }
        },
    },
    watch: {
        show: {
            handler(val) {
                if (val) {
                    switch (this.type * 1) {
                        case 1:
                            this.importTitle = "导入";
                        case 2:
                            this.importTitle = "导出";
                    }

                    this.filterList = this.deepCopy(this.columns);
                }
            },
            //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
            //两者相同都是首次加载不会执行
            immediate: true,
            //deep:true,深度监听，可以监听对象属性
            deep: true,
        },
        isfinish: {
            handler(val) {
                if (val) {
                    this.divLoading = false;
                    this.stepsActive = this.stepList.length;
                }
            },
            //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
            //两者相同都是首次加载不会执行
            immediate: true,
            //deep:true,深度监听，可以监听对象属性
            deep: true,
        },
        importError: {
            handler(val) {
                if (val) {
                    this.divLoading = false;
                }
            },
            //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
            //两者相同都是首次加载不会执行
            immediate: true,
            //deep:true,深度监听，可以监听对象属性
            deep: true,
        },
        //
        stepsActive: {
            handler(val) {
                this.steps_type = this.stepList.filter(
                    (o) => o.id * 1 === val * 1
                )[0].type;
            },
            //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
            //两者相同都是首次加载不会执行
            immediate: true,
            //deep:true,深度监听，可以监听对象属性
            deep: true,
        },
    },
    methods: {
        //列表选择
        columnsValueChange(e, value) {
            let index = this.checkedColumnsValue.findIndex(
                (o) => o.name === value
            );
            if (index > -1 && !e) {
                this.checkedColumnsValue.splice(index, 1);
                console.log(11111);
            } else {
                this.checkedColumnsValue.push({ name: value, key: e });
            }
        },
        //切换导出模板
        templateChange(e) {
            if (!e) {
                this.filterList = this.deepCopy(this.columns);
                return false;
            }
            //获取已经选中的模板
            let index = this.exportTemplateList.findIndex(
                (o) => o.id * 1 === e * 1
            );
            let list =
                index > -1
                    ? JSON.parse(this.exportTemplateList[index].fieldinfo)
                    : [];
            let array = [];
            //根据模板重新排序
            list.forEach((item) => {
                let i = this.filterList.findIndex(
                    (o) => o.field === item.field
                );
                if (i > -1) {
                    let obj = this.filterList[i];
                    obj.isCheck = item.isCheck;
                    obj.byname = item.byname;
                    array.push(obj);
                }
            });
            this.filterList = this.deepCopy(array);
        },
        //切换工作表
        worksheetChange(e) {
            this.getImportList(
                this.worksheet[e].ws,
                2,
                this.worksheet[e].columns
            );
        },
        //禁用选项
        importColumnsDisabled(item, value, row) {
            let isDisabled = false;
            let index = this.checkedColumnsValue.findIndex(
                (o) => o.key === value
            );
            return index > -1;
        },
        //上传
        upload(file, fileList) {
            let files = [file];
            let imgSize = Number(file.size / 1024 / 1024);
            if (files.length <= 0) {
                this.$msgbox({
                    title: "",
                    message: "上传失败请重新上传",
                    type: "warning",
                });
                return false;
            }
            if (imgSize > 10) {
                this.$msgbox({
                    title: "",
                    message: "文件大小不能超过10MB，请重新上传。",
                    type: "warning",
                });
                return false;
            }
            if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
                this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
                return;
            }
            // this.readExcel1(files);
            this.parseExcel(files[0]);

            return false; //false表示不需要上传~
        },
        parseExcel(file) {
            var that = this;
            this.divLoading = true;
            this.loading_text = "上传中~";

            this.filename = file.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = e.target.result;
                    const workbook = read(data, { type: "binary" });
                    this.worksheet = [];
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];

                    // 生成json表格内容
                    workbook.SheetNames.forEach((wsname) => {
                        let ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                        const jsonData = utils.sheet_to_json(
                            workbook.Sheets[wsname],
                            {
                                header: 1,
                            }
                        );
                        // 获取表头，通常是第一行
                        const headerRow = jsonData[0] || [];

                        let strings = workbook.Sheets[wsname];
                        let array = [];
                        for (let i = 0; i < headerRow.length; i++) {
                            array.push({
                                field: headerRow[i],
                                title: headerRow[i],
                                minWidth: 100,
                            });
                        }

                        let obj = {
                            wsname: wsname,
                            ws: ws,
                            columns: array,
                        };

                        this.worksheet.push(obj);
                    });
                    // 后续为自己对ws数据的处理
                    this.form.region = 0;

                    this.getImportList(
                        this.worksheet[0].ws,
                        1,
                        this.worksheet[0].columns
                    );
                } catch (e) {
                    this.divLoading = false;
                    return false;
                }

                // 将表头和数据存储在组件的数据属性中
                // this.excelHeader = headerRow;
                // this.excelData = dataRows;
            };
            reader.readAsBinaryString(file);
        },
        // 表格导入
        readExcel1(files) {
            var that = this;
            this.divLoading = true;
            this.loading_text = "上传中~";

            this.filename = files[0].name;
            // 此处判断不严谨，思路只是根据传入的参数校验数据的完整性，可根据自己需求修改
            // 如果没有文件名

            const fileReader = new FileReader();

            fileReader.onload = (ev) => {
                try {
                    const data = ev.target.result;

                    const workbook = read(data, {
                        type: "binary",
                    });

                    this.worksheet = [];

                    // 生成json表格内容
                    workbook.SheetNames.forEach((wsname) => {
                        let ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                        let strings = workbook.Sheets[wsname];
                        let array = [];
                        Object.keys(strings).filter((o) => {
                            if (o.indexOf(1) > -1) {
                                array.push({
                                    field: strings[o].v,
                                    title: strings[o].v,
                                    minWidth: 100,
                                    // slots: {
                                    //     default: ({ row }) => {
                                    //         return [<el-input   placeholder="请选择"></el-input>];
                                    //     },
                                    // },
                                });
                            }
                        });
                        // <el-select
                        //                     v-model="item.value"
                        //                     placeholder="请选择"
                        //                     style="width: 100%"
                        //                     clearable
                        //                     v-if="type * 1 === 1"
                        //                     @change="
                        //                         (e) =>
                        //                             columnsValueChange(
                        //                                 e,
                        //                                 item.title
                        //                             )
                        //                     "
                        //                 >
                        //                     <el-option
                        //                         :label="value.title"
                        //                         :value="value.field"
                        //                         v-for="(
                        //                             value, value_i
                        //                         ) in columns"
                        //                         :key="value_i"
                        //                         :disabled="
                        //                             importColumnsDisabled(
                        //                                 item,
                        //                                 value.field,
                        //                                 row
                        //                             )
                        //                         "
                        //                     ></el-option>
                        //                 </el-select>
                        // columns.forEach((item) => {});
                        let obj = {
                            wsname: wsname,
                            ws: ws,
                            columns: array,
                        };

                        this.worksheet.push(obj);
                    });

                    // 后续为自己对ws数据的处理
                    this.form.region = 0;

                    this.getImportList(
                        this.worksheet[0].ws,
                        1,
                        this.worksheet[0].columns
                    );
                } catch (e) {
                    this.divLoading = false;
                    return false;
                }
            };
            // fileReader.readAsText(files[0]);

            fileReader.readAsBinaryString(files[0], "UTF-8");
        },
        getImportList(list, type, columns) {
            let length = 0;
            let maxIndex = 0;
            if (type * 1 === 2) {
                this.divLoading = true;
                this.loading_text = "加载中~";
            }
            if (list.length > 5000) {
                this.divLoading = false;
                this.$msgbox({
                    title: "",
                    message:
                        "单个sheet页数据量不超过5000行,请切换工作表或重新上传",
                    type: "warning",
                });
                return false;
            }

            this.importColumns = columns;

            this.importList = list;
            this.divLoading = false;
            this.stepsActive += 1;
        },

        //全选
        allCheckboxChange() {
            if (this.checkboxLength === this.filterList.length) {
                this.filterList.forEach((o) => (o.isCheck = false));
            } else {
                this.filterList.forEach((o) => (o.isCheck = true));
            }
        },
        //选择
        checkboxChange(item) {
            let i = this.filterList.findIndex((o) => o.title === item.title);
            this.filterList[i].isCheck = !this.filterList[i].isCheck;
            this.$emit("sortConfirm", {
                data: this.filterList,
                type: "check",
            });
        },
        importSubmit(type) {
            // this.$message.success('操作成功')
            if (type === 1 || type === 5) {
                this.$prompt("请输入模板名称", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                    // inputErrorMessage: '邮箱格式不正确'
                })
                    .then(({ value }) => {
                        if (type === 5) {
                            this.exportTemplate(value);
                            return false;
                        }

                        this.$emit("update:show", false);
                        this.stepsActive = 1;
                    })
                    .catch(() => {
                        // this.$message({
                        //   type: 'info',
                        //   message: '取消输入'
                        // });
                    });
            } else {
                this.stepsActive = 1;
                this.$emit("update:show", false);
            }
        },
        //保存导出模板
        exportTemplate(value) {
            let data = { template_name: value, list: [] };
            this.filterList.forEach((item) => {
                let obj = {
                    field: item.field,
                    isCheck: item.isCheck,
                };

                let obj1 = {
                    byname: item.byname,
                };

                let obj2 = item.byname ? { ...obj, ...obj1 } : obj;
                data.list.push(obj2);
            });
            this.$emit("saveExportTemplate", data);
        },
        close() {
            this.$emit("close", false);
            this.stepsActive = 1;
        },
        //导出提交按钮
        exportSubmit() {
            let list = [];
            this.filterData.forEach((o) => {
                if (o.isCheck) {
                    let obj = {
                        field: o.field,
                    };
                    let obj1 = {
                        byname: o.byname,
                    };
                    let obj2 = o.byname ? { ...obj, ...obj1 } : obj;

                    list.push(obj2);
                }
            });

            this.$emit("exportSubmit", list);
            this.$emit("update:show", false);
            // this.$message.success("操作成功");
        },
        stepsActiveChange(type) {
            if (type === 1) {
                this.stepsActive -= 1;
                return;
            }
            if (this.stepsActive * 1 === this.stepList.length - 1) {
                this.divLoading = true;
                this.loading_text = "导入中~";
                this.onSubmit();
                return;
            }
            if (this.stepsActive * 1 === 2) {
                this.btnLoading = true;
                //默认选中

                setTimeout(async () => {
                    try {
                        // 执行你的数据处理操作
                        await this.performDataProcessing();
                    } catch (error) {
                    } finally {
                        this.btnLoading = false;
                    }
                });

                // return false
            }
            this.stepsActive += 1;
        },
        performDataProcessing() {
            for (let i = 0; i < this.importColumns.length; i++) {
                let item = this.importColumns[i];
                let index = this.columns.findIndex(
                    (o) => o.title === item.title
                );

                if (index > -1) {
                    item.value = this.columns[index].field;
                    this.columnsValueChange(
                        this.columns[index].field,
                        item.title
                    );
                }
            }
            // this.btnLoading = false;
        },
        onSubmit() {
            let modifiedArray = [];
            // 创建一个新对象来保存更新后的键值对
            this.divLoading = true;
            // 遍历当前对象的键值对
            for (let i = 0; i < this.importList.length; i++) {
                let obj = this.importList[i];
                let data = {};
                for (let key in obj) {
                    // 检查是否是要修改的键
                    let index = this.importColumns.findIndex(
                        (o) => o.title === key
                    );
                    if (index > -1 && this.importColumns[index].value) {
                        data[this.importColumns[index].value] = obj[key];

                        // break;
                    }
                }
                modifiedArray.push(data);
            }
            this.$emit("importsubmit", {
                datalist: modifiedArray,
                importoption: Object.assign(this.importoption, {
                    filename: this.filename,
                }),
            });
        },
        handleImportSuccess(res, file) {
            this.stepsActive += 1;
        },
        download() {
            if (this.mid == "47328400087045") {
                this.downloadtplfile = "/downfile/设备风险管控导入模版.xls";
            }
            if (this.mid == "47329610733573") {
                this.downloadtplfile = "/downfile/环境风险管控导入模版.xls";
            }
            if (this.mid == "47179889879045") {
                this.downloadtplfile = "/downfile/作业风险管控导入模版.xls";
            }
            if (this.downloadtplfile) {
                const link = document.createElement("a");
                link.href = this.downloadtplfile;
                link.download = "模板.xlsx";
                link.click();
            } else {
                try {
                    this.downloadbyurl(this.downloadtplurl);
                } catch (error) {
                    console.log(chalk.red(error));
                }
            }
        },
    },
};
</script>

<style lang="scss">
.import-dialog .vxe-table--tooltip-wrapper {
    z-index: 10000 !important;
}

.import-dialog {
    .sortable-ghost {
        background: #fff !important;
        // opacity: 0 !important;
    }

    .sortable-chosen {
        .icon-caiddang {
            color: #1890ff;
        }

        background: #fff !important;
        border: 1px solid #1890ff;
        color: #1890ff;
        opacity: 1 !important;
    }

    .btn-orange {
        background-color: #ff9648;
        color: #fff;
    }
}

.import-upload {
    width: 100%;
    margin-bottom: 15px;

    &-tips1 {
        // font-size: 12px;
        line-height: 1.5;
        color: #000;
        font-weight: 600;
        margin-bottom: 25px;
        // font-size: 15px;
    }

    &-tips {
        // font-size: 12px;
        line-height: 1.5;
    }

    .el-upload {
        width: 100%;

        .el-upload-dragger {
            width: 100%;
        }
    }

    &-list {
        width: 90%;
        margin: 0 auto;
        // padding-top: 10px;
    }

    &-form {
        display: flex;
        // justify-content: space-between;
        width: 100%;
        padding: 10px 0;

        &-item {
            flex: 1;

            .required-tips {
                font-size: 12px;
                margin-top: 5px;
                color: red;
                display: none;
            }
        }

        &-label {
            margin-bottom: 5px;

            .import-required {
                color: red;
                line-height: 14px;
            }
        }
    }
}

.import-success {
    text-align: center;
    padding: 50px 0;

    &-icon {
        font-size: 40px;
        color: #67c23a;
        margin-bottom: 10px;
    }

    &-tips {
        margin-top: 10px;

        .text-primary {
            color: #1890ff;
            cursor: pointer;
        }
    }
}

.column {
    margin-top: 10px;
    // border: 1px solid #d9d9d9;
    // border-radius: 6px;
    // padding: 15px;

    .column-list {
        // margin-top: 20px;
        padding: 5px 15px 15px 0;
        max-height: 300px;
        overflow-y: auto;

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            line-height: 1;
            padding: 10px;
            margin-top: 10px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            background: #fff;

            .icon {
                position: relative;
                z-index: 9999;
            }
            .el-input-group__prepend {
                padding: 0 5px !important;
            }

            // &:hover {
            // 	border: 1px solid #1890ff;
            // 	color: #1890ff;
            // }
        }
    }
}

// .custom-dragging {
// 	background-color:#1890ff;
// }

.sortable-ghost {
    background: #fff !important;
    // opacity: 0 !important;
}

.sortable-chosen {
    .icon-caiddang {
        color: #1890ff;
    }

    background: #fff !important;
    border: 1px solid #1890ff;
    color: #1890ff;
    opacity: 1 !important;
}
.import-el-table {
    min-height: 100px;
}
.import-el-table .el-table th.el-table__cell {
    background-color: transparent !important;
}
</style>